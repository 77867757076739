import React, { useContext, useEffect, useState } from "react";
import { Col, DatePicker, Form, Input, Row, Select, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { UploadOutlined } from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import { Modal } from "components/modals/antd-modals";
import { DriverProps } from "components/drivers/driverDataTableColumns";
import openNotificationWithIcon from "utility/notification";
import { CodebookApi, DriversApi, UsersApi } from "api/api";
import Dragger from "antd/lib/upload/Dragger";
import { CommonDataContext } from "contexts/CommonDataContext/CommonDataContext";
import { Button } from "components/buttons/buttons";
import { base64ToFile, downloadFilesFromBase64 } from "utility/dowloadFiles";
import { ModalMode } from "hooks/useModalState";
import moment from "moment";

const codebookApi = new CodebookApi();
const driversApi = new DriversApi();
const usersApi = new UsersApi();

const { Option } = Select;

const CODEBOOK_DRIVING_CATEGORIES = "CODEBOOK_DRIVING_CATEGORIES";

const Driver: React.FC<DriverProps> = ({
  onSave,
  isVisible,
  onHide,
  mode = ModalMode.VIEW,
  driverToEdit,
  companyId,
}) => {
  const { t } = useTranslation();

  const commonData = useContext(CommonDataContext);
  const { languages } = commonData as any;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false);

  const [categoriesDrive, setCategoriesDrive] = useState<any[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const [existingFiles, setExistingFiles] = useState<any[]>([]);

  const [form] = Form.useForm();
  const readOnlyMode = mode === ModalMode.VIEW;
  const updateMode = mode === ModalMode.UPSERT;

  useEffect(() => {
    if (driverToEdit) {
      fetchUserDetails();
    } else {
      form.resetFields();
      setFileList([]);
      setExistingFiles([]);
    }
  }, [form, driverToEdit, mode]);

  const fetchUserDetails = async () => {
    try {
      setIsLoading(true);
      const { data } = await driversApi.apiDriversIdGet({
        id: driverToEdit?.id as any,
      });

      const formattedDriverToEdit = {
        ...data,
        expirationDate: data?.expirationDate
          ? moment(data?.expirationDate)
          : null,
        entitledCategoriesOfDrive: data.entitledCategoriesOfDrive,
      };
      console.log("data driver: ",formattedDriverToEdit);
      console.log("data driver2: ",data);
      setExistingFiles(
        data?.files?.map((file) => ({
          fileName: file.fileName,
          deleted: false,
        })) ?? []
      );
      setFileList(
        readOnlyMode ? data?.files ?? [] : data?.files?.map(base64ToFile) ?? []
      );

      form.setFieldsValue(formattedDriverToEdit);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCodebookByType = async () => {
    const response: any = (
      await codebookApi.apiCodebookByParentCodeGet({
        code: CODEBOOK_DRIVING_CATEGORIES,
        pageSize: -1,
      })
    ).data;

    setCategoriesDrive(response.items);
  };

  const handleOnSave = async () => {
    setIsBtnLoading(true);
    try {
      const values = await form.validateFields();

      const formattedValues = {
        ...values,

        expirationDate: values.expirationDate
          ? values.expirationDate.toISOString()
          : new Date().toISOString(),
      };

      if (driverToEdit) {
        await driversApi.apiDriversIdPut({
          id: driverToEdit.id,
          ...formattedValues,
          companyId,
          existingFileNames: [...existingFiles]
            .filter((x) => !x.deleted)
            .map((x) => x.fileName),
          toBeDeletedFileNames: [...existingFiles]
            .filter((x) => x.deleted)
            .map((x) => x.fileName),
          userFiles: fileList,
        });
        openNotificationWithIcon(
          "success",
          t(
            "driverModal:driver-edited-success",
            "Driver is edited successfuly!"
          )
        );
      } else {
        await driversApi.apiDriversPost({
          ...formattedValues,
          companyId,
          userFiles: fileList,
          entitledCategoriesOfDrive: values.entitledCategoriesOfDrive,
        });

        openNotificationWithIcon(
          "success",
          t(
            "driverModal:driver-created-success",
            "Driver is created successfuly!"
          )
        );
      }
      if (onHide && onSave) {
        onHide();
        onSave("" as any);
      }
    } catch (error) {
    } finally {
      setIsBtnLoading(false);
    }
  };

  useEffect(() => {
    fetchCodebookByType();
  }, []);

  return (
    <Modal
      title={t("driver:add.title", "Add Driver")}
      visible={isVisible}
      onOk={handleOnSave}
      onCancel={onHide}
      type="primary"
      loading={isBtnLoading}
      hideButtons={mode === ModalMode.VIEW}
    >
      {isLoading ? (
        <Spin />
      ) : (
        <Form form={form} layout="vertical" name="driversForm">
          <Row gutter={[15, 15]}>
            <Col xs={24} md={12}>
              <Form.Item
                name="firstName"
                label="First name"
                rules={[
                  {
                    required: true,
                    message: t(
                      "driver:enter-first-Name",
                      "Please enter first name"
                    ),
                  },
                ]}
              >
                <Input disabled={mode === ModalMode.VIEW} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="lastName"
                label="Last name"
                rules={[
                  {
                    required: true,
                    message: t(
                      "driver:enter-last-Name",
                      "Please enter last name"
                    ),
                  },
                ]}
              >
                <Input disabled={mode === ModalMode.VIEW} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="personalNumber"
                label="Personal number"
                rules={[
                  {
                    required: true,
                    message: t(
                      "driver:enter-personal-Number",
                      "Please enter personal number"
                    ),
                  },
                ]}
              >
                <Input disabled={mode === ModalMode.VIEW} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: t("driver:enter-email", "Please enter email"),
                  },
                ]}
              >
                <Input disabled={mode === ModalMode.VIEW} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="languageId"
                label="Language"
                rules={[
                  {
                    required: true,
                    message: t(
                      "driver:enter-language",
                      "Please select language"
                    ),
                  },
                ]}
              >
                <Select size="large" disabled={mode === ModalMode.VIEW}>
                  {languages?.map((item: any) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="expirationDate"
                label="Expiration date"
                rules={[
                  {
                    required: true,
                    message: t(
                      "driver:enter-expiration-Date",
                      "Please enter expiration date"
                    ),
                  },
                ]}
                getValueProps={(i) => ({ value: moment(i) })}
              >
                <DatePicker
                  style={{ width: "100%", height: 50, paddingLeft: "10px" }}
                  disabled={mode === ModalMode.VIEW}
                  format="YYYY-MM-DD"
                  defaultValue={moment()}
                  placeholder={t(
                    "driver:input.expirationDate",
                    "Expiration date"
                  )}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="issuingAuthority"
                label="Issuing Authority"
                rules={[
                  {
                    required: true,
                    message: t(
                      "driver:enter-issuing-Authority",
                      "Please enter issuing authority"
                    ),
                  },
                ]}
              >
                <Input disabled={mode === ModalMode.VIEW} />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="entitledCategoriesOfDrive"
                label="Driving licence categories"
                rules={[
                  {
                    required: true,
                    message: t(
                      "driver:enter-drive-categories",
                      "Please select drive categories"
                    ),
                  },
                ]}
              >
                <Select
                  disabled={mode === ModalMode.VIEW}
                  size="large"
                  mode="multiple"
                  allowClear
                >
                  {categoriesDrive?.map((item: any) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col md={24}>
              <Form.Item>
                {!readOnlyMode ? (
                  <Dragger
                    fileList={fileList}
                    beforeUpload={(file) => {
                      setFileList((prev: any): any => [...prev, file]);
                      return false;
                    }}
                    onRemove={(file) => {
                      if (updateMode) {
                        setExistingFiles((prev: any) =>
                          prev.map((x: any) => {
                            if (x.fileName.includes(file.name)) {
                              x.deleted = true;
                            }
                            return x;
                          })
                        );
                      }
                      const index = fileList.indexOf(file as never);
                      const newFileList = fileList.slice();
                      newFileList.splice(index, 1);
                      setFileList(newFileList);
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <UploadOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file(s) to this area to upload
                    </p>
                  </Dragger>
                ) : (
                  fileList?.length !== 0 && (
                    <Button
                      type="white"
                      onClick={() => downloadFilesFromBase64(fileList)}
                    >
                      <FeatherIcon icon="download" size="24" />
                      Download files
                    </Button>
                  )
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Modal>
  );
};

export default Driver;
