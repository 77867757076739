/* tslint:disable */
/* eslint-disable */
/**
 * Logisticx App API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddDestinationsCommand } from '../models';
// @ts-ignore
import { DeleteDestinationCommand } from '../models';
// @ts-ignore
import { DestinationDtoOrdinalPaginatedList } from '../models';
// @ts-ignore
import { UpdateDestinationCommand } from '../models';
/**
 * DestinationsApi - axios parameter creator
 * @export
 */
export const DestinationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [companyId] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {string} [companyId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesDestinationsGet: async (companyId?: string, pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, companyId2?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/companies/destinations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['SortBy'] = sortBy;
            }

            if (sortType !== undefined) {
                localVarQueryParameter['SortType'] = sortType;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (companyId2 !== undefined) {
                localVarQueryParameter['CompanyId'] = companyId2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {DeleteDestinationCommand} [deleteDestinationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesDestinationsIdDelete: async (id: string, deleteDestinationCommand?: DeleteDestinationCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCompaniesDestinationsIdDelete', 'id', id)
            const localVarPath = `/api/companies/destinations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteDestinationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDestinationCommand} [updateDestinationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesDestinationsIdPut: async (id: string, updateDestinationCommand?: UpdateDestinationCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCompaniesDestinationsIdPut', 'id', id)
            const localVarPath = `/api/companies/destinations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDestinationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddDestinationsCommand} [addDestinationsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesDestinationsPost: async (addDestinationsCommand?: AddDestinationsCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/companies/destinations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addDestinationsCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DestinationsApi - functional programming interface
 * @export
 */
export const DestinationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DestinationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [companyId] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {string} [companyId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompaniesDestinationsGet(companyId?: string, pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, companyId2?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DestinationDtoOrdinalPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesDestinationsGet(companyId, pageNumber, pageSize, sortBy, sortType, search, companyId2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {DeleteDestinationCommand} [deleteDestinationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompaniesDestinationsIdDelete(id: string, deleteDestinationCommand?: DeleteDestinationCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesDestinationsIdDelete(id, deleteDestinationCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDestinationCommand} [updateDestinationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompaniesDestinationsIdPut(id: string, updateDestinationCommand?: UpdateDestinationCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesDestinationsIdPut(id, updateDestinationCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddDestinationsCommand} [addDestinationsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCompaniesDestinationsPost(addDestinationsCommand?: AddDestinationsCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCompaniesDestinationsPost(addDestinationsCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DestinationsApi - factory interface
 * @export
 */
export const DestinationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DestinationsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [companyId] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [sortBy] 
         * @param {string} [sortType] 
         * @param {string} [search] 
         * @param {string} [companyId2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesDestinationsGet(companyId?: string, pageNumber?: number, pageSize?: number, sortBy?: string, sortType?: string, search?: string, companyId2?: string, options?: any): AxiosPromise<DestinationDtoOrdinalPaginatedList> {
            return localVarFp.apiCompaniesDestinationsGet(companyId, pageNumber, pageSize, sortBy, sortType, search, companyId2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {DeleteDestinationCommand} [deleteDestinationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesDestinationsIdDelete(id: string, deleteDestinationCommand?: DeleteDestinationCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiCompaniesDestinationsIdDelete(id, deleteDestinationCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDestinationCommand} [updateDestinationCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesDestinationsIdPut(id: string, updateDestinationCommand?: UpdateDestinationCommand, options?: any): AxiosPromise<void> {
            return localVarFp.apiCompaniesDestinationsIdPut(id, updateDestinationCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddDestinationsCommand} [addDestinationsCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCompaniesDestinationsPost(addDestinationsCommand?: AddDestinationsCommand, options?: any): AxiosPromise<string> {
            return localVarFp.apiCompaniesDestinationsPost(addDestinationsCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiCompaniesDestinationsGet operation in DestinationsApi.
 * @export
 * @interface DestinationsApiApiCompaniesDestinationsGetRequest
 */
export interface DestinationsApiApiCompaniesDestinationsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DestinationsApiApiCompaniesDestinationsGet
     */
    readonly companyId?: string

    /**
     * 
     * @type {number}
     * @memberof DestinationsApiApiCompaniesDestinationsGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof DestinationsApiApiCompaniesDestinationsGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof DestinationsApiApiCompaniesDestinationsGet
     */
    readonly sortBy?: string

    /**
     * 
     * @type {string}
     * @memberof DestinationsApiApiCompaniesDestinationsGet
     */
    readonly sortType?: string

    /**
     * 
     * @type {string}
     * @memberof DestinationsApiApiCompaniesDestinationsGet
     */
    readonly search?: string

    /**
     * 
     * @type {string}
     * @memberof DestinationsApiApiCompaniesDestinationsGet
     */
    readonly companyId2?: string
}

/**
 * Request parameters for apiCompaniesDestinationsIdDelete operation in DestinationsApi.
 * @export
 * @interface DestinationsApiApiCompaniesDestinationsIdDeleteRequest
 */
export interface DestinationsApiApiCompaniesDestinationsIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DestinationsApiApiCompaniesDestinationsIdDelete
     */
    readonly id: string

    /**
     * 
     * @type {DeleteDestinationCommand}
     * @memberof DestinationsApiApiCompaniesDestinationsIdDelete
     */
    readonly deleteDestinationCommand?: DeleteDestinationCommand
}

/**
 * Request parameters for apiCompaniesDestinationsIdPut operation in DestinationsApi.
 * @export
 * @interface DestinationsApiApiCompaniesDestinationsIdPutRequest
 */
export interface DestinationsApiApiCompaniesDestinationsIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof DestinationsApiApiCompaniesDestinationsIdPut
     */
    readonly id: string

    /**
     * 
     * @type {UpdateDestinationCommand}
     * @memberof DestinationsApiApiCompaniesDestinationsIdPut
     */
    readonly updateDestinationCommand?: UpdateDestinationCommand
}

/**
 * Request parameters for apiCompaniesDestinationsPost operation in DestinationsApi.
 * @export
 * @interface DestinationsApiApiCompaniesDestinationsPostRequest
 */
export interface DestinationsApiApiCompaniesDestinationsPostRequest {
    /**
     * 
     * @type {AddDestinationsCommand}
     * @memberof DestinationsApiApiCompaniesDestinationsPost
     */
    readonly addDestinationsCommand?: AddDestinationsCommand
}

/**
 * DestinationsApi - object-oriented interface
 * @export
 * @class DestinationsApi
 * @extends {BaseAPI}
 */
export class DestinationsApi extends BaseAPI {
    /**
     * 
     * @param {DestinationsApiApiCompaniesDestinationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DestinationsApi
     */
    public apiCompaniesDestinationsGet(requestParameters: DestinationsApiApiCompaniesDestinationsGetRequest = {}, options?: AxiosRequestConfig) {
        return DestinationsApiFp(this.configuration).apiCompaniesDestinationsGet(requestParameters.companyId, requestParameters.pageNumber, requestParameters.pageSize, requestParameters.sortBy, requestParameters.sortType, requestParameters.search, requestParameters.companyId2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DestinationsApiApiCompaniesDestinationsIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DestinationsApi
     */
    public apiCompaniesDestinationsIdDelete(requestParameters: DestinationsApiApiCompaniesDestinationsIdDeleteRequest, options?: AxiosRequestConfig) {
        return DestinationsApiFp(this.configuration).apiCompaniesDestinationsIdDelete(requestParameters.id, requestParameters.deleteDestinationCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DestinationsApiApiCompaniesDestinationsIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DestinationsApi
     */
    public apiCompaniesDestinationsIdPut(requestParameters: DestinationsApiApiCompaniesDestinationsIdPutRequest, options?: AxiosRequestConfig) {
        return DestinationsApiFp(this.configuration).apiCompaniesDestinationsIdPut(requestParameters.id, requestParameters.updateDestinationCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DestinationsApiApiCompaniesDestinationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DestinationsApi
     */
    public apiCompaniesDestinationsPost(requestParameters: DestinationsApiApiCompaniesDestinationsPostRequest = {}, options?: AxiosRequestConfig) {
        return DestinationsApiFp(this.configuration).apiCompaniesDestinationsPost(requestParameters.addDestinationsCommand, options).then((request) => request(this.axios, this.basePath));
    }
}
