import { Table } from "antd";
import FeatherIcon from "feather-icons-react";
import { useTranslation } from "react-i18next";

import Destination from "./destination";
import { Cards } from "components/cards/frame/cards-frame";
import {
  DestinationProps,
  useDestinationColumns,
} from "components/destinations/destinationDataTableColumns";
import { Button } from "components/buttons/buttons";

import { DestinationsApi } from "api/api";

import { TableWrapper } from "container/styled";
import { useDestinationsFetch } from "./hooks/useDestinationsFetch";

import { hasPermission } from "utility/accessibility/hasPermission";
import openNotificationWithIcon from "utility/notification";
import { useModalState } from "hooks/useModalState";

const destinationsApi = new DestinationsApi();

const Destinations = ({ companyId }: DestinationProps) => {
  const { t } = useTranslation();
  const { data, isLoading, fetchDestinations, setQuery } = useDestinationsFetch(
    { companyId }
  );

  const { modalState, closeModal, handleAddClick } =
    useModalState(fetchDestinations);

  const canAddDestination = hasPermission("destinations:add");

  const columns = useDestinationColumns({
    handleDeleteClick: async (item: any) => {
      try {
        await destinationsApi.apiCompaniesDestinationsIdDelete({ id: item.id, deleteDestinationCommand:{
          destinationId: item.id,
          companyId : companyId
        } });
        fetchDestinations();
        openNotificationWithIcon(
          "success",
          t(
            "destinationModal:destination-delete-success",
            "Destination deleted successfully!"
          )
        );
      } catch (error) {}
    },
  });

  return (
    <div style={{ width: "100%" }}>
      <Cards
        title={t("destinations:title", "Destinations")}
        size="large"
        isbutton={
          canAddDestination && (
            <Button
              onClick={handleAddClick}
              className="btn-add_new"
              size="default"
              type="white"
              key="add-codebook"
            >
              <FeatherIcon icon="plus" size={25} />
              {t("destinations:button.add", "Add New")}
            </Button>
          )
        }
      >
        <TableWrapper className="table-responsive">
          <Table
            loading={isLoading}
            dataSource={data?.items}
            columns={columns}
            rowKey="id"
            pagination={{
              current: data?.pageIndex,
              total: data?.totalCount,
              showSizeChanger: true,
              pageSizeOptions: ["10", "50", "100", "1000"],
              onChange: (pageNumber, pageSize) =>
                setQuery((prev) => ({ ...prev, pageNumber, pageSize })),
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
            }}
          />
        </TableWrapper>
      </Cards>
      <Destination
        isVisible={modalState.addModalVisible}
        onHide={closeModal}
        onSave={fetchDestinations}
        companyId={companyId}
      />
    </div>
  );
};

export default Destinations;
